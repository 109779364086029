.category-head {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 250px;
    @media(max-width:1024px){
        margin-top: 100px;
    }

    h3 {

        font-family: 'ArgentumSans-300';
        color: #E0390F;
        font-size: 40px;
        font-style: normal;
        @media(max-width:1024px){
            font-size: 30px;
        }
        @media(max-width:400px){
            font-size: 20px;
        }


        span {
            font-family: 'ArgentumSans-300';
            color: #fff;
            font-size: 40px;
            font-style: normal;
            margin-left: 10px;
            @media(max-width:1024px){
                font-size: 30px;
            }
            @media(max-width:400px){
                font-size: 20px;
            }
        }
    }

}

.eclipse_category{
    width: 60%;
    @media(max-width:1920px){
        width: 60%;
    }
    @media(max-width: 1540px){
        width: 60%;
    }
    @media(max-width:640px){
        width: 80%;
    }
}