.round{
    width: 40%;
}

.mainHero{
    
    @media(max-width:1920px){
        height: 100%;
    }

    // .battery-img{
    //     width: 90%;
    //     @media(max-width:1920px){
    //         width: 80%;
    //     }
    // }
}

// .first-section{
//     @media(max-width:1920px){
//         margin-top: 10%;
//     }
// }