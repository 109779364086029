.mobilecategory-head {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;

    h3 {

        font-family: 'ArgentumSans-400';
        color: #E0390F;
        font-size: 30px;
        font-style: normal;
        @media (max-width:600px) {
            font-size: 20px;
        }
        @media (max-width:300px) {
            font-size: 15px;
        }


        span {
            font-family: 'ArgentumSans-400';
            color: #fff;
            font-size: 30px;
            font-style: normal;
            margin-left: 10px;
            @media (max-width:600px) {
                font-size: 20px;
            }
            @media (max-width:300px) {
                font-size: 15px;
            }
        }
    }

}

.mobilecategory-section {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20%;
    padding-top: 50px;

    .mobilecategory {
        background-color: #fff;
        width: 88px;
        height: 88px;
        border-radius: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s ease;
        @media(max-width:550px){
            width: 58px;
            height: 58px;
        }
        @media(max-width:350px){
            width: 38px;
            height: 38px;
        }

        img {
            width: 50%;
            height: 50%;
        }

        &:hover {
            background-color: #E0390F;
        }
    }
    
   

}
.category-sub{
    width: 100%;
    display: flex;
    gap: 20%;
    margin-top: 50px;
    justify-content: center;
    @media(max-width:400px){
        gap: 18%;
    }
    @media(max-width:300px){
        gap: 15%;
    }
    h3 {
        font-family: 'ArgentumSans-300';
        font-size: 16px;
        font-style: normal;
        color: #fff;
        position: relative;
        text-align: center;
        @media(max-width:550px){
            font-size: 12px;
        }
        @media(max-width:400px){
            font-size: 10px;
        }
    }

}