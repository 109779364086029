.hero-content{
    position: absolute;
    left: 8%;
    top: 150px;
    @media(max-width:1400px){
        top: 80px;
    }
  
    h5 {
        color: white;
        font-family: 'ArgentumSans-600';
        font-size: 2rem;
        font-style: normal;
        text-align: left;
        width: 519px;
        @media(max-width:1920px){
            font-size: 32px;
            width: 400px;
        }
        @media(max-width:1540px){
            font-size: 40px;
            width: 300px;
        }
        @media(max-width:1130px){
            font-size: 30px;
            width: 300px;
        }
        
    }
    
    p {
        color: #fff;
        font-family: 'ArgentumSans-300';
        font-size: 32px;
        font-style: normal;
        text-align: left;
        @media(max-width:1620px){
            font-size: 30px;
            
        }
        @media(max-width:1250px){
            font-size: 25px;
           
        }
        @media(max-width:1250px){
            font-size: 20px;
            
        }
    }

}

.round_one{
    width: 35%;
    @media(max-width:1920px){
        width: 35%;
    }
    @media(max-width: 1540px){
        width: 33%;
    }
}