@font-face {
    font-family: 'ArgentumSans-900';
    src: url(../fonts/ArgentumSans-Black.ttf) format('opentype');
}
@font-face {
    font-family: 'ArgentumSans-800';
    src: url(../fonts/ArgentumSans-ExtraBold.ttf) format('opentype');
}
@font-face {
    font-family: 'ArgentumSans-700';
    src: url(../fonts/ArgentumSans-Bold.ttf) format('opentype');
}
@font-face {
    font-family: 'ArgentumSans-600';
    src: url(../fonts/ArgentumSans-SemiBold.ttf) format('opentype');
}
@font-face {
    font-family: 'ArgentumSans-500';
    src: url(../fonts/ArgentumSans-Medium.ttf) format('opentype');
}
@font-face {
    font-family: 'ArgentumSans-400';
    src: url(../fonts/ArgentumSans-Regular.ttf) format('opentype');
}
@font-face {
    font-family: 'ArgentumSans-300';
    src: url(../fonts/ArgentumSans-Light.ttf) format('opentype');
}
@font-face {
    font-family: 'ArgentumSans-200';
    src: url(../fonts/ArgentumSans-ExtraLight.ttf) format('opentype');
}
@font-face {
    font-family: 'ArgentumSans-100';
    src: url(../fonts/ArgentumSans-Thin.ttf) format('opentype');
}