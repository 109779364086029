.eclipse{
    width: 33%;
    @media(max-width:1920px){
        width: 30%;
    }
    @media(max-width: 1540px){
        width: 33%;
    }
}


.head{
   margin-top: 30%;
}

.battery{
    top: 150px;
}