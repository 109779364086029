.footer-section {
    background-color: #111;
    padding-top: 30px;

    .footer-main {
        display: flex;
        padding-left: 120px;

        @media(max-width:1210px) {
            padding-left: 60px;
        }

        .footer-section-one {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;



            .footer-logo {
                width: 303px;
                height: 114px;

                @media(max-width:1210px) {
                    width: 250px;
                    height: 84px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .footer-address {
                padding-top: 60px;

                h5 {
                    font-family: 'ArgentumSans-400';
                    color: #E53A10;
                    font-size: 16px;
                    font-style: normal;
                    text-align: center;

                    @media(max-width:1210px) {
                        font-size: 14px;
                    }
                }

                p {
                    font-family: 'ArgentumSans-300';
                    color: #fff;
                    font-size: 16px;
                    font-style: normal;
                    text-align: center;
                    width: 364px;

                    @media(max-width:1210px) {
                        font-size: 14px;
                        width: 300px;
                    }
                }
            }

            .footer-Email {
                display: flex;
                gap: 30px;
                margin-top: 30px;

                h5 {
                    font-family: 'ArgentumSans-300';
                    color: #E53A10;
                    font-size: 16px;
                    font-style: normal;
                    text-align: center;
                    margin: 0;
                    padding-top: 3px;

                    @media(max-width:1210px) {
                        font-size: 14px;
                    }
                }

                p {
                    font-family: 'ArgentumSans-300';
                    color: #fff;
                    font-size: 16px;
                    font-style: normal;
                    text-align: center;
                    margin: 0;

                    @media(max-width:1210px) {
                        font-size: 14px;
                    }
                }
            }
        }

        .footer-section-two {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 100px;
            gap: 70px;

            @media(max-width:1210px) {
                gap: 40px;
            }

            .footer-part-one {

                display: flex;

                h4 {
                    font-family: 'ArgentumSans-400';
                    color: #E53A10;
                    font-size: 20px;
                    font-style: normal;
                    padding-bottom: 36px;

                    @media(max-width:1210px) {
                        font-size: 16px;
                    }

                }

                p {
                    font-family: 'ArgentumSans-300';
                    color: #fff;
                    font-size: 16px;
                    font-style: normal;

                    @media(max-width:1210px) {
                        font-size: 14px;
                    }

                }
            }

        }

    }

    .copy-rights {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0px;

        h5 {
            font-family: 'ArgentumSans-400';
            font-size: 15px;
            font-style: normal;
            color: #fff;

            @media(max-width:1210px) {
                font-size: 12px;
            }

            .orange-text {
                color: #E53A10;
            }
        }
    }
}