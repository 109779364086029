@import './fonts.scss';
.mob-about{
    width: 100%;
    margin-top: 50px;
    @media(min-width:767px){
        margin-top: 100px;
    }
    .mob-about-section{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            color: #E0390F;
            text-align: center;
            font-family: 'ArgentumSans-400';
            span {
                color: #fff;
            }
        }
        .mob-para{
            color: #fff;
            font-family: 'ArgentumSans-300';
            font-size: 14px;
            text-align: center;
            padding-top: 50px;
            padding-left: 80px;
            padding-right: 80px;
            @media(max-width: 500px){
                padding-left: 40px;
                padding-right: 40px;
                font-size: 12px;
            }
            @media(max-width: 370px){
                padding-left: 20px;
                padding-right: 20px;
                font-size: 10px;
            }
        }
        .mob-para-second{
            color: #fff;
            font-family: 'ArgentumSans-300';
            font-size: 14px;
            text-align: center;
            padding-top: 20px;
            padding-left: 110px;
            padding-right: 110px;
            @media(max-width: 500px){
                padding-left: 60px;
                padding-right: 60px;
                font-size: 12px;
            }
            @media(max-width: 370px){
                padding-left: 30px;
                padding-right: 30px;
                font-size: 10px;
            }
        }
        .mobcustomer-service {
            display: flex;
            align-items: center;
            padding-top: 50px;

            img {
                height: 32px;
                width: 32px;
                @media(max-width: 400px){
                    height: 20px;
                    width: 20px;
                }
                @media(max-width: 330px){
                    height: 15px;
                    width: 15px;
                }

            }

            h3 {
                color: #E53A10;
                font-family: 'ArgentumSans-400';
                font-size: 16px;
                font-style: normal;
                margin: 0;
                padding-left: 5px;
                @media(max-width: 400px){
                    font-size: 14px;
                }
                @media(max-width: 330px){
                   font-size: 12px;
                }
               
            }

            p {
                color: #fff;
                font-family: 'ArgentumSans-400';
                font-size: 16px;
                font-style: normal;
                margin: 0;
                padding-left: 20px;
                @media(max-width: 400px){
                    font-size: 14px;
                }
                @media(max-width: 350px){
                   font-size: 12px;
                }
                
            }
        }
    }
   
}
.mobform {
    width: 100%;
    margin-top: 50px;
    width: 100%;
    @media(min-width:767px){
        margin-top: 100px;
    }
    .mobform-head {
        h3 {
            font-size: 25px;
            font-style: normal;
            color: #E0390F;
            text-align: center;
            font-family: 'ArgentumSans-400';


            span {
                color: #fff;
            }
        }
    }

    .mobform-content {
        padding-top: 45px;
        width: 100%;

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 17px;
            width: 100%;
            input {
                background-color: #323232;
                border-radius: 20px;
                width: 60%;
                height: 58px;
                border: none;
                text-align: center;
            }

            .message {
                background-color: #323232;
                border-radius: 20px;
                width: 60%;
                height: 202px;
                border: none;
                text-align: center;
            }

            button {
                background-color: #E0390F;
                border-radius: 20px;
                height: 58px;
                color: #fff;
                border: none;
                width: 60%;
            }
        }
    }
}