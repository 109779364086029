.mobilearrival-head {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    @media(max-width:400px){
        margin-top: 40px;
    }

    .hot {
        font-family: 'ArgentumSans-400';
        color: #E0390F;
        font-size: 30px;
        font-style: normal;
        @media(max-width:400px){
            font-size: 20px;
        }
    }

    .deal {
        font-family: 'ArgentumSans-400';
        color: #fff;
        font-size: 30px;
        font-style: normal;
        margin-left: 10px;
        @media(max-width:400px){
            font-size: 20px;
        }
    }
}

.mobilearrival-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 100px;
    width: 100%;
    padding: 10px;
    @media(max-width:550px){
        margin-top: 50px;
    }

    .mobarrival-partone {
       

        .mobilearrival-first {
            background-color: #111;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 30px;
            height: 600px;
            border-radius: 20px;
            @media(max-width:550px){
                height: 350px;
            }
            @media(max-width:400px){
                height: 250px;
            }
            
            


            .mobilerounded-part {
                width: 203px;
                height: 203px;
                border-radius: 165px;
                background-color: #E53A10;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media(max-width:550px){
                    width: 150px;
                    height: 150px;
                }
                @media(max-width:400px){
                    width: 100px;
                    height: 100px;
                }
                

                &:hover {
                    width: 80%;
                    border-radius: 10px;
                    transform: scale(1.1);
                    /* Add zoom effect */
                    transition: border-radius 0.3s ease, transform 0.3s ease;
                }

                .mobilebattery {
                    width: 151px;
                    height: 111px;
                    margin-top: 10px;
                    padding-bottom: 20px;
                    @media(max-width:550px){
                        width: 121px;
                        height: 100px;
                    }
                    @media(max-width:400px){
                        width: 81px;
                        height: 65px;
                    }


                }

                h2 {
                    font-size: 22px;
                    font-style: normal;
                    color: #E0390F;
                    font-family: 'ArgentumSans-500';
                    padding-top: 20px;
                    @media(max-width:550px){
                        font-size: 18px;
                    }
                    @media(max-width:400px){
                        font-size: 12px;
                    }
                    
                    



                }

                h3 {
                    margin: 0;
                    padding-top: 50px;
                    font-family: 'ArgentumSans-400';
                    padding-bottom: 20px;
                    font-size: 12px;
                    font-style: normal;
                    @media(max-width:550px){
                        font-size: 10px;
                        padding-top: 20px;
                    }
                    @media(max-width:400px){
                        font-size: 8px;
                       padding-bottom: 5px;
                    }

                }

                h4 {
                    margin: 0;
                    font-family: 'ArgentumSans-400';
                    font-size: 12px;
                    font-style: normal;
                    color: #fff;

                    @media(max-width:550px){
                        font-size: 10px;
                    }
                    @media(max-width:400px){
                        font-size: 6px;
                    }
                }

                p {
                    margin: 0;
                    font-family: 'ArgentumSans-400';
                    color: #fff;
                    font-size: 14px;
                    font-style: normal;
                    @media(max-width:550px){
                        font-size: 12px;
                    }
                    @media(max-width:400px){
                        font-size: 6px;
                    }
                  
                }
            }
        }
    }
}