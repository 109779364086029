@import './fonts.scss';

.mobileheader-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #000;
    height: 90px;
    // position: fixed;
    z-index: 10;
    // @media(min-width:767px){
    //     position: static;
    // }

    .mobileheader-logo {
        width: 50%;
        display: flex;
        align-items: center;
       

        .logo {
            width: 153px;
            height: 70px;
            margin-top: 10px;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .mobileheader-menu {
        display: flex;
        align-items: flex-end;
        margin-top: 0px;
        justify-content: center;
        width: 50%;
        gap: 8px;
        // @media(min-width:767px){
        //    justify-content: end;
        // }
        
        
        
        

        img {
            width: 30px;
            height: 30px;
        }
    }
}

.mobilehero-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 20px;
    z-index: -1;
    @media(max-width:610px){
        bottom: 220px;
    }
    @media(max-width:500px){
        bottom: 170px;
    }
    @media(max-width:350px){
        bottom: 120px;
    }
    @media(max-width:270px){
        bottom: 70px;
    }
    h5{
        color: #E53A10;
        font-family: 'ArgentumSans-400';
        font-size: 30px;
        font-style: normal;
        text-align: left;
        width: 309px;
        text-align: center;
        @media(max-width:400px){
            font-size: 24px;
            width: 250px;
        }
        @media(max-width:300px){
            font-size: 20px;
            width: 200px;
        }
    }
    p{  
        text-align: center;
        color: #fff;
        font-family: 'ArgentumSans-300';
        font-size: 20px;
        font-style: normal;
        text-align: left;
        @media(max-width:400px){
            font-size: 16px;
           
        }
        @media(max-width:400px){
            font-size: 14px;
           
        }
    }

}
.hero-img{
    margin-top: 100px;
    @media(max-width:685px){
        margin-top: 150px;
    }
}