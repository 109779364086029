.about-main {
    width: 100%;
    padding-bottom: 141px;

    .about-section {

        .about-head {
            h3 {
                font-size: 35px;
                font-style: normal;
                font-weight: 400;
                color: #E0390F;
                text-align: left;

                span {
                    color: #fff;
                }
            }
        }

        .about-para {
            color: #fff;
            padding-top: 50px;

            .first-para {
                width: 484px;
                font-family: 'ArgentumSans-300';
                font-size: 18px;
                font-style: normal;
                @media(max-width:1350px){
                    font-size: 16px;
                    width: 400px;
                }
            }

            .second-para {
                width: 525px;
                font-family: 'ArgentumSans-300';
                font-size: 18px;
                font-style: normal;
                padding-top: 20px;
                @media(max-width:1350px){
                    font-size: 16px;
                    width: 400px;
                }
            }
        }

        .about-account {
            display: flex;
            align-items: center;
            gap: 50px;
            padding-top: 50px;

            .follow-us {

                p {
                    padding-top: 20px;
                    font-size: 18px;
                    font-style: normal;
                    font-family: 'ArgentumSans-300';
                    color: #929292;
                    @media(max-width:1200px){
                        font-size: 18px;
                       
                    }
                    
                }
            }

            .social-accounts {
                display: flex;
                gap: 10px;


                div {
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 43px;
                    height: 43px;
                    border-radius: 5px;
                    

                    img {
                        width: 23px;
                        height: 23px;
                        @media(max-width:1200px){
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }

        .customer-service {
            display: flex;
            align-items: center;
            padding-top: 50px;

            img {
                height: 32px;
                width: 32px;

            }

            h3 {
                color: #E53A10;
                font-family: 'ArgentumSans-400';
                font-size: 20px;
                font-style: normal;
                margin: 0;
                padding-left: 5px;
                @media(max-width:1200px){
                    font-size: 18px;
                    // width: 400px;
                }
            }

            p {
                color: #fff;
                font-family: 'ArgentumSans-400';
                font-size: 20px;
                font-style: normal;
                margin: 0;
                padding-left: 10px;
                @media(max-width:1200px){
                    font-size: 18px;
                   
                }
            }
        }
    }

    .form {
        width: 100%;
        .form-head {
            h3 {
                font-size: 35px;
                font-style: normal;
                font-weight: 400;
                color: #E0390F;
                text-align: left;

                span {
                    color: #fff;
                }
            }
        }

        .form-content {
            padding-top: 45px;
            width: 100%;

            form {
                display: flex;
                flex-direction: column;
                gap: 17px;
                width: 100%;
                input {
                    background-color: #323232;
                    border-radius: 20px;
                    width: 90%;
                    height: 58px;
                    border: none;
                    text-align: center;
                }

                .message {
                    background-color: #323232;
                    border-radius: 20px;
                    width: 90%;
                    height: 202px;
                    border: none;
                    text-align: center;
                }

                button {
                    background-color: #e53a10;
                    border-radius: 20px;
                    height: 58px;
                    color: #fff;
                    border: none;
                    width: 90%;
                }
            }
        }
    }
}