@import './fonts.scss';

.header-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    .header-logo {
        width: 50%;
        display: flex;
        align-items: center;
        .logo {
            width: 250px;
            margin-top: 10px;
            @media(max-width:1200px){
                width: 200px;
                height: 64px;
            }
            
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .header-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        gap: 40px;
        
        h3 {
            color: #fff;
            font-family: 'ArgentumSans-300';
            font-size: 18px;
            font-style: normal;
            text-align: center;
            margin-top: 30px;
            transition: all 0.3s ease-in-out;
            @media(max-width:1200px){
                font-size: 14px;
            }
           &:hover {
               color: #E53A10;
               cursor: pointer;
           } 
        }
    }
}