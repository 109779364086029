.product-head {
    display: flex;
    justify-content: center;
    margin-top: 200px;


    .hot {
        font-family: 'ArgentumSans-300';
        color: #E0390F;
        font-size: 40px;
        font-style: normal;
    }

    .deal {
        font-family: 'ArgentumSans-300';
        color: #fff;
        font-size: 40px;
        font-style: normal;
        margin-left: 10px;
    }
}

.product-section {
    
   
    @media(max-width:1440px){
        padding-left: 50px;
        padding-right: 50px;
    }
   
    @media(max-width:993px){
        padding-left: 0px;
        padding-right: 0px;
    }


    
}

.product-first {
    position: relative;
    background-color: #111;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    justify-content: center;
    width: 100%;
    padding-bottom: 400px;
    padding-top: 180px;
    height: 600px;
    border-radius: 5px;
    transition: all 0.3s ease, transform 0.3s ease;
    @media(max-width:1440px){
        padding-bottom: 350px;
    }
    @media(max-width:992px){
        padding-bottom: 250px;
        padding-top: 50px;
    }
    &:hover {
        transition: border-radius 0.3s ease, transform 0.3s ease;
        .rounded-part {
            width:80%;
            border-radius: 10px;
            transform: scale(1.1); /* Add zoom effect */
            transition: border-radius 0.3s ease, transform 0.3s ease;
        }
    }

    .rounded-part {
        width: 303px;
        height: 303px;
        border-radius: 150px;
        background-color: #E53A10;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width:1440px){
            width: 260px;
            height: 260px;
        }
        @media(max-width:1200px){
            width: 230px;
            height: 230px;
        }
        @media(max-width:993px){
            width: 200px;
            height: 200px;
        }
        // &:hover {
           
        //   }

        .battery {
            width: 250px;
            height: 250px;
            margin-top: 20px;
            padding-bottom: 20px;
            @media(max-width:1440px){
                width: 187px;
                height: 157px;
            }

        }

        h2 {
            font-size: 32px;
            font-style: normal;
            color: #E0390F;
            font-family: 'ArgentumSans-500';
            padding-top: 20px;
            @media(max-width:1440px){
                font-size: 26px;
            }


        }

        h3 {
            margin: 0;
            padding-top: 50px;
            font-family: 'ArgentumSans-400';
            padding-bottom: 20px;
            font-size: 15px;
            font-style: normal;
            @media(max-width:1440px){
                font-size: 12pxpx;
            }
        }

        h4 {
            margin: 0;
            font-family: 'ArgentumSans-400';
            font-size: 15px;
            font-style: normal;
            color: #fff;
            @media(max-width:1440px){
                font-size: 12px;
            }

        }

        p {
            margin: 0;
            font-family: 'ArgentumSans-400';
            color: #fff;
            font-size: 18px;
            font-style: normal;
            @media(max-width:1440px){
                font-size: 15px;
            }

        }
    }
    .button_enquiry{
        position: absolute;
        bottom: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
       
    }
}