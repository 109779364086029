.mob-footer-section{
    width: 100%;
    background-color: #111;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
    .mob-footer-logo{
         width: 103px;
         height: 47px;
        margin-top: 30px;
         img{
            width: 100%;
            height: 100%;
         }
    }
    .mob-address{
        margin-top: 30px;
        h3{
            margin: 0;
            color: #E0390F;
            font-size: 12px;
            text-align: center;
            font-family: 'ArgentumSans-400';
            @media(max-width:500px){
                font-size: 10px;
            }

        }
        p{
             color: #fff;
             font-size: 14px;
             font-family: 'ArgentumSans-300';
             text-align: center;
             padding-left: 100px;
             padding-right: 100px;
             @media(max-width:500px){
                font-size: 10px;
            }

        }
    }
    .mobfooter-Email {
        display: flex;
        gap: 30px;
        margin-top: 30px;
        align-items: center;

        h5 {
            font-family: 'ArgentumSans-300';
            color: #E53A10;
            font-size: 16px;
            font-style: normal;
            text-align: center;
            margin: 0;
            padding: 0;
            @media(max-width:500px){
                font-size: 10px;
            }
        }

        p {
            font-family: 'ArgentumSans-300';
            color: #fff;
            font-size: 16px;
            font-style: normal;
            text-align: center;
            margin: 0;
            @media(max-width:500px){
                font-size: 10px;
            }
        }
    }
    h3{
        color: #fff;
        text-align: center;
        font-family: 'ArgentumSans-300';
        font-size: 12px;
        margin-top: 70px;
    }
    .mobsocial-accounts {
        display: flex;
        gap: 10px;
        margin-top: 20px;

        div {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 43px;
            height: 43px;
            border-radius: 5px;
            

            img {
                width: 23px;
                height: 23px;
                @media(max-width:1200px){
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    .mob-rights {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 50px;

        h5 {
            font-family: 'ArgentumSans-300';
            font-size: 12px;
            font-style: normal;
            color: #fff;
            @media(max-width:500px){
                font-size: 6px;
            }

            .orange-text {
                color: #E53A10;
            }
        }
    }
}